export interface MultiLingualString {
  [key: string]: string;
}

export interface PagingMetaData {
  firstItemOnPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isFirstPage: boolean;
  isLastPage: boolean;
  lastItemOnPage: number;
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  totalItemCount: number;
}

export interface PagedList<T> {
  data: T[];
  paging: PagingMetaData;
}

export function getEmptyPagedList<T>() {
  const pagedList: PagedList<T> = {
    data: [],
    paging: {
      firstItemOnPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      isFirstPage: false,
      isLastPage: false,
      lastItemOnPage: 0,
      pageCount: 0,
      pageNumber: 0,
      pageSize: 0,
      totalItemCount: 0,
    },
  };
  return pagedList;
}

export enum UserType {
  Unknown = "Unknown",
  Administrator = "Administrator",
  Customer = "Customer",
}

export const USER_FIRST_NAME_MAX_LENGTH = 50;
export const USER_LAST_NAME_MAX_LENGTH = 50;
export const USER_EMAIL_ADDRESS_MAX_LENGTH = 254;

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userType: UserType;
  createdOn: string;
  lastActiveOn: string;
  isGlobalActive: boolean;
  isEmailVerified: boolean;
  unverifiedEmailAddress?: string;
}

export function getEmptyUser() {
  const user: User = {
    createdOn: "",
    emailAddress: "",
    firstName: "",
    id: 0,
    isGlobalActive: false,
    isEmailVerified: false,
    lastActiveOn: "",
    lastName: "",
    userType: UserType.Unknown,
  };
  return user;
}

export const ORGANIZATION_NAME_MAX_LENGTH = 500;

export interface Organization {
  id: number;
  name: string;
  isActive: boolean;
  createdOn: string;
  countryId: string;
  currencyId: number;
  industryId?: number;
  ktId?: number;
  stripeCustomerId: string;

  country: Country;
  currency: Currency;
  imagePath: string;
}

export enum OrganizationUserRole {
  Unknown = "Unknown",
  Owner = "Owner",
  Manager = "Manager",
  Viewer = "Viewer",
}

export interface OrganizationUser {
  id: number;
  organizationId: number;
  userId: number;
  userRole: OrganizationUserRole;
  isActive: boolean;
  joinedOn: string;
  invitedOn: string;
}

export interface OrganizationUserInvite {
  id: number;
  organizationId: number;
  emailAddress: string;
  userRole: OrganizationUserRole;
  invitedOn: string;
}

export enum ColumnPosition {
  Left = "Left",
  Right = "Right",
}

export enum PersonaFieldType {
  SingleLineText = "SingleLineText",
  MultiLineText = "MultiLineText",
  Number = "Number",
}

export const PERSONA_FIELD_NAME_MAX_LENGTH = 30;

export interface PersonaField {
  id: number;
  personaId: number;
  columnPosition: ColumnPosition;
  sortOrder: number;
  fieldType: PersonaFieldType;
  fieldName: string;
  fieldData?: string;
}

export const PERSONA_NAME_MAX_LENGTH = 100;

export interface Persona {
  id: number;
  name: string;
  organizationId: number;
  avatarImagePath?: string;
  fields: PersonaField[];

  avatar?: File;
}

export function getEmptyPersona() {
  const persona: Persona = {
    id: 0,
    name: "",
    organizationId: 0,
    fields: [],
  };
  return persona;
}

export interface JourneyMapPersona {
  id: number;
  personaId: number;
  journeyMapId: number;
  persona: Persona;
}

export const FOLDER_NAME_MAX_LENGTH = 100;

export interface Folder {
  id: number;
  name: string;
  organizationId: number;
}

export enum LaneType {
  Unknown = "Unknown",
  Channels = "Channels",
  CustomImage = "CustomImage",
  CustomText = "CustomText",
  Attachments = "Attachments",
  StepRating = "StepRating",
  Metrics = "Metrics",
  Diagram = "Diagram",
  ProcessFlow = "ProcessFlow",
}

export enum LaneTypeNum {
  Unknown = 0,
  Channels = 1,
  CustomImage = 2,
  CustomText = 3,
  Attachments = 4,
  StepRating = 5,
  Metrics = 6,
  Diagram = 7,
  ProcessFlow = 8,
}

export const STEP_ATTACHMENT_FILE_NAME_MAX_LENGTH = 100;
export const STEP_ATTACHMENT_FILE_PATH_MAX_LENGTH = 100;
export const STEP_ATTACHMENT_FILE_MIME_TYPE_MAX_LENGTH = 100;
export const STEP_ATTACHMENT_DESCRIPTION_MAX_LENGTH = 100;

export interface StepAttachment {
  id: number;
  journeyMapId: number;
  stepId: number;
  laneId: number;
  fileName: string;
  fileSize: number;
  filePath: string;
  fileMimeType: string;
  isMerged: boolean;
  mergedIds: string;
}

export const STEP_CUSTOM_IMAGE_FILE_NAME_MAX_LENGTH = 100;
export const STEP_CUSTOM_IMAGE_FILE_PATH_MAX_LENGTH = 100;
export const STEP_CUSTOM_IMAGE_FILE_MIME_TYPE_MAX_LENGTH = 100;
export const STEP_CUSTOM_IMAGE_DESCRIPTION_MAX_LENGTH = 100;

export interface StepCustomImage {
  id: number;
  journeyMapId: number;
  stepId: number;
  laneId: number;
  fileName: string;
  fileSize: number;
  filePath: string;
  fileMimeType: string;
  isMerged: boolean;
  mergedIds: string;
}

export const STEP_CHANNEL_ICON_MAX_LENGTH = 50;
export const STEP_CHANNEL_NAME_MAX_LENGTH = 100;
export const STEP_CHANNEL_DESCRIPTION_MAX_LENGTH = 1000;

export interface StepChannel {
  id: number;
  journeyMapId: number;
  stepId: number;
  laneId: number;
  icon: string;
  name: string;
  description?: string;
  isMerged: boolean;
  mergedIds: string;
  MergedStepIds: any;
}

export function getEmptyStepChannel() {
  const channel: StepChannel = {
    id: 0,
    journeyMapId: 0,
    stepId: 0,
    laneId: 0,
    icon: "",
    name: "",
    isMerged: false,
    mergedIds: "",
    MergedStepIds: [],
  };
  return channel;
}

export const STEP_CUSTOM_VALUE_BACKGROUND_COLOR_MAX_LENGTH = 7;

export interface StepCustomValue {
  id: number;
  journeyMapId: number;
  laneId: number;
  stepId: number;
  content: string;
  backgroundColor?: string;
  isMerged: boolean;
  mergedIds: string;
  MergedStepIds?: any;
}

export function getEmptyStepCustomValue() {
  const stepCustomValue: StepCustomValue = {
    id: 0,
    journeyMapId: 0,
    laneId: 0,
    stepId: 0,
    content: "",
    isMerged: false,
    mergedIds: "",
  };
  return stepCustomValue;
}

export enum StepRating {
  Unspecified = "Unspecified",
  Level1 = "Level1",
  Level2 = "Level2",
  Level3 = "Level3",
  Level4 = "Level4",
  Level5 = "Level5",
}

export const STEP_NAME_MAX_LENGTH = 500;
export const STEP_DESCRIPTION_MAX_LENGTH = 1000;
export const STEP_BADGE_COLOR_MAX_LENGTH = 6;
export const STEP_BADGE_SYMBOL_MAX_LENGTH = 20;
export const STEP_BADGE_TITLE_MAX_LENGTH = 200;
export const STEP_BACKGROUND_COLOR_MAX_LENGTH = 7;

export interface Step {
  id: number;
  name: string;
  journeyMapId: number;
  stageId: number;
  sortOrder: number;
  rating: StepRating;
  description?: string;
  showBadge: boolean;
  isHiddenInSummary: boolean;
  badgeColor?: string;
  badgeSymbol?: string;
  badgeTitle?: string;
  backgroundColor?: string;
  linkedJourneyMapId?: number;
  isMerged: boolean;
  mergedIds: string;
  isDraggable: boolean;
}

export function getEmptyStep() {
  const step: Step = {
    id: 0,
    journeyMapId: 0,
    name: "",
    stageId: 0,
    sortOrder: 0,
    rating: StepRating.Unspecified,
    showBadge: false,
    isHiddenInSummary: false,
    isMerged: false,
    mergedIds: "",
    isDraggable: true,
  };
  return step;
}

export const LANE_NAME_MAX_LENGTH = 100;
export const LANE_BACKGROUND_COLOR_MAX_LENGTH = 7;

export interface Lane {
  id: number;
  name: string;
  journeyMapId: number;
  laneType: LaneType;
  isHiddenInSummary: boolean;
  backgroundColor?: string;
  isMerged: boolean;
  mergedIds: string;
}

export const STAGE_NAME_MAX_LENGTH = 100;

export interface Stage {
  id: number;
  name: string;
  journeyMapId: number;
  isHiddenInSummary: boolean;
}

export function getEmptyStage() {
  const stage: Stage = {
    id: 0,
    name: "",
    journeyMapId: 0,
    isHiddenInSummary: false,
  };
  return stage;
}

export const JOURNEY_MAP_NAME_MAX_LENGTH = 200;
export const JOURNEY_MAP_DESCRIPTION_MAX_LENGTH = 500;

export interface JourneyMap {
  id: number;
  name: string;
  description?: string;
  organizationId: number;
  createdOn: string;
  updatedOn: string;
  creatorUserId: number;
  isActive: boolean;
  isPublished: boolean;
  isChildMap: boolean;
  hasChildMaps: boolean;
  folderId?: number;
  strengthCutoff?: number;
  weaknessCutoff?: number;
  neutralStart?: number;
  neutralEnd?: number;

  folder?: Folder;
  creatorUser: User;
  journeyMapPersonas: JourneyMapPersona[];
}

export function getEmptyJourneyMap() {
  const journeyMap: JourneyMap = {
    createdOn: "",
    creatorUserId: 0,
    hasChildMaps: false,
    id: 0,
    isActive: false,
    isChildMap: false,
    isPublished: false,
    name: "",
    organizationId: 0,
    updatedOn: "",

    creatorUser: getEmptyUser(),
    journeyMapPersonas: [],
  };
  return journeyMap;
}

export enum JourneyMapTemplate {
  Blank = "Blank",
  CustomerJourney = "CustomerJourney",
  ServiceBlueprint = "ServiceBlueprint",
}

export interface DropDownItem {
  id: number;
  name: MultiLingualString;
}

export interface Country {
  id: string;
  name: string;
  taxName: string;
  taxRate: number;
}

export interface StepMetricReading {
  id: number;
  journeyMapId: number;
  stepId: number;
  laneId: number;
  stepMetricId: number;
  readingTime: string;
  readingValue: number;
}

export enum MetricDataSource {
  Unknown = "Unknown",
  DataUpload = "DataUpload",
  IntegrationKt = "IntegrationKt",
}

export enum MeasurementFrequency {
  Unknown = "Unknown",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
}

export enum MeasurementFormula {
  Unknown = "Unknown",
  Sum = "Sum",
  Average = "Average",
  Count = "Count",
}

export enum GoalAchievement {
  Unknown = "Unknown",
  EqualOrMore = "EqualOrMore",
  EqualOrLess = "EqualOrLess",
}

export enum ReadingDataType {
  Unknown = "Unknown",
  Percentage = "Percentage",
  Numeric = "Numeric",
  Currency = "Currency",
}

export const STEP_METRIC_NAME_MAX_LENGTH = 200;
export const STEP_METRIC_READING_SYMBOL_MAX_LENGTH = 20;

export interface StepMetric {
  id: number;
  journeyMapId: number;
  stepId: number;
  laneId: number;
  stageId: number;
  name: string;
  sortOrder: number;
  source: MetricDataSource;
  measurementFrequency: MeasurementFrequency;
  measurementFormula?: MeasurementFormula;
  goal: number;
  goalAchievement: GoalAchievement;
  readingDataType: ReadingDataType;
  readingSymbol?: string;
  readingImportedAt?: string;
  readingCount: number;
  readings: StepMetricReading[];
  questionsSyncedAt?: string;
  isMerged: boolean;
  mergedIds: string;
}

export function getEmptyStepMetric() {
  const metric: StepMetric = {
    id: 0,
    journeyMapId: 0,
    stepId: 0,
    laneId: 0,
    stageId: 0,
    name: "",
    sortOrder: 0,
    source: MetricDataSource.Unknown,
    measurementFrequency: MeasurementFrequency.Unknown,
    goal: 0,
    goalAchievement: GoalAchievement.Unknown,
    readingDataType: ReadingDataType.Unknown,
    readingCount: 0,
    readings: [],
    isMerged: false,
    mergedIds: "",
  };
  return metric;
}

export enum QuestionnaireType {
  Assignment = "Assignment",
  Survey = "Survey",
}

export interface StepMetricQuestionAnswers {
  id: number;
  questionId: number;
  answerId: number;
  stepMetricQuestionId: number;
  text?: string;
}
export interface StepMetricQuestion {
  id: number;
  stepId: number;
  laneId: number;
  stepMetricId: number;
  questionnaireId: number;
  questionnaireType: QuestionnaireType;
  questionId: number;
  rowQuestionId?: number;
  tabularQuestionId?: number;
  stepMetricQuestionAnswers?: StepMetricQuestionAnswers[];
}

export interface Currency {
  id: number;
  name: string;
  symbol: string;
  currencyCode: string;
  isDefault: boolean;
}

export interface ProductPrice {
  id: number;
  productId: number;
  currencyId: number;
  monthPrice: number;
  yearPrice: number;
  stripeMonthPriceId: string;
  stripeYearPriceId: string;

  currency: Currency;
}

export interface Product {
  id: number;
  name: MultiLingualString;
  features: MultiLingualString;
  isActive: boolean;
  isEnterprise: boolean;
  isDefault: boolean;
  sortOrder: number;

  // Product Features
  maxManagerCount: number;
  maxViewerCount: number;
  maxJourneyMapCount: number;
  maxStorageMb: number;

  // Stripe
  stripeProductId: string;

  productPrices: ProductPrice[];
}

export enum BillingCycle {
  Unknown = "Unknown",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
}

export enum PaymentMethod {
  Unknown = "Unknown",
  CreditCard = "CreditCard",
  WireTransfer = "WireTransfer",
}

export enum SubscriptionStatus {
  Incomplete = "Incomplete",
  IncompleteExpired = "IncompleteExpired",
  Trialing = "Trialing",
  Active = "Active",
  PastDue = "PastDue",
  Canceled = "Canceled",
  Unpaid = "Unpaid",
}

export interface Subscription {
  id: number;
  organizationId: number;
  productId: number;
  productPriceId: number;
  currencyId: number;
  createdOn: string;
  cancelOn?: string;
  canceledOn?: string;
  currentPeriodStartDate: string;
  currentPeriodEndDate: string;
  billingCycle: BillingCycle;
  paymentMethod: PaymentMethod;
  subscriptionStatus: SubscriptionStatus;
  trialExtensionCount: number;

  product: Product;
  productPrice: ProductPrice;
  currency: Currency;
  organization: Organization;
}

export interface OrganizationUserItem {
  // User
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userType: UserType;
  createdOn: string;
  lastActiveOn: string;
  isActive: boolean;
  isEmailVerified: boolean;
  unverifiedEmailAddress?: string;

  // OrganizationUser
  organizationUserId: number;
  userRole: OrganizationUserRole;
  joinedOn: string;
  invitedOn: string;
}
