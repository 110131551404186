import Axios from "axios";
import { differenceInCalendarDays } from "date-fns";
import React, { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import Select, { OnChangeValue } from "react-select";

import AppState from "src/AppState";
import Avatar from "src/components/Avatar";
import { logout, updateUserContext } from "src/features/auth/actions";
import { UserContext, MyThunkDispatch } from "src/features/auth/types";
import { setOrganization } from "src/features/organizations/actions";
import { hasManagerAccess } from "src/helpers";
import { Organization, OrganizationUserRole, SubscriptionStatus, UserType } from "src/models";
import useLanguage from "src/helpers/useLanguage";

const logo = require("src/features/app/mapovate-logo-white.png");

type OrganizationOption = {
  value: number;
  label: string;
};

const SiteHeader = () => {
  const { t, i18n } = useTranslation();
  const userContext = useSelector<AppState, UserContext>((state) => state.authState.userContext);
  const organizations = useSelector<AppState, Organization[]>((state) => state.organizationsState.organizations);
  const dispatch = useDispatch<MyThunkDispatch>();
  const history = useHistory();
  const { otherLanguageOptions, currentLanguage } = useLanguage();

  const userRole = userContext.currentOrganizationUser?.userRole || OrganizationUserRole.Unknown;
  const userType = userContext.currentUser?.userType || UserType.Unknown;

  const userFullName =
    userContext && userContext.currentUser
      ? `${userContext.currentUser.firstName} ${userContext.currentUser.lastName}`
      : "";

  const currentOrganization = userContext && userContext.currentOrganization;
  const currentSubscription = userContext && userContext.currentSubscription;

  const selectedOption: OrganizationOption | undefined = currentOrganization
    ? { value: currentOrganization.id, label: currentOrganization.name }
    : undefined;

  const options: OrganizationOption[] = useMemo(() => {
    return organizations.map((x) => {
      return { value: x.id, label: x.name };
    });
  }, [organizations]);

  const logoutAction = () => {
    dispatch(logout()).then(() => {
      window.location.assign("/");
    });
  };

  const handleOrganizationChange = async (
    value: OnChangeValue<OrganizationOption, false> | OnChangeValue<OrganizationOption, true>
  ) => {
    const organizationId = (value as OrganizationOption).value;

    if (organizationId !== currentOrganization?.id) {
      await dispatch(setOrganization(organizationId));
      history.push("/journey-maps");
      await dispatch(updateUserContext());
    }
  };

  const handleLanguageChange = async (language: string) => {
    await Axios.post(`/api/locales/change-language/${language}`);
    i18n.changeLanguage(language);
  };

  const userProfileBlock = <Avatar size={30} text={userFullName} className="rounded" />;

  let trialPeriodRemainingDays = -1;
  let trialPeriodPhrase = "TrialPeriodEndNote_Ended";

  if (currentSubscription && currentSubscription.subscriptionStatus === SubscriptionStatus.Trialing) {
    const startDate = new Date();
    const endDate = new Date(currentSubscription.currentPeriodEndDate);
    trialPeriodRemainingDays = differenceInCalendarDays(endDate, startDate);

    if (trialPeriodRemainingDays === 0) {
      trialPeriodPhrase = "TrialPeriodEndNote_Today";
    } else if (trialPeriodRemainingDays === 1) {
      trialPeriodPhrase = "TrialPeriodEndNote_Tomorrow";
    } else if (trialPeriodRemainingDays > 1) {
      trialPeriodPhrase = "TrialPeriodEndNote_X_Days";
    }
  }

  return (
    <header id="site-header">
      <Navbar bg="dark" variant="dark" fixed="top">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} width="127" height="30" alt={t("Mapovate")} />
        </Navbar.Brand>
        <Nav className="mr-auto ml-5">
          {userType === UserType.Administrator && (
            <Nav.Link as={NavLink} to="/" exact>
              {t("Organizations")}
            </Nav.Link>
          )}
          {currentOrganization && (
            <>
              <Nav.Link as={NavLink} to="/journey-maps">
                {t("JourneyMaps")}
              </Nav.Link>
              {hasManagerAccess(userRole) && (
                <Nav.Link as={NavLink} to="/personas">
                  {t("Personas")}
                </Nav.Link>
              )}
            </>
          )}
        </Nav>
        {currentSubscription && currentSubscription.subscriptionStatus === SubscriptionStatus.Trialing && (
          <div className="trial-bar">
            <div>{t(trialPeriodPhrase, { count: trialPeriodRemainingDays })}</div>
            {userRole === OrganizationUserRole.Owner && <Link to="/account/billing">{t("Upgrade")}</Link>}
          </div>
        )}
        {currentOrganization && organizations.length > 1 && (
          <div className="mr-4 w-25">
            <Select
              options={options}
              defaultValue={selectedOption}
              onChange={handleOrganizationChange}
              classNamePrefix="org_select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#3aa7d0",
                  primary25: "#dff1f7",
                  primary50: "#dff1f7",
                  neutral0: "#fff",
                  neutral80: "rgba(255, 255, 255, 0.5)",
                  neutral60: "rgba(255, 255, 255, 0.5)",
                  neutral50: "rgba(255, 255, 255, 0.5)",
                  neutral40: "rgba(255, 255, 255, 0.5)",
                  neutral30: "rgba(255, 255, 255, 0.4)",
                  neutral20: "rgba(255, 255, 255, 0.2)",
                },
              })}
            />
          </div>
        )}
        <Nav>
          <Dropdown as={NavItem}>
            <Dropdown.Toggle to="#" as={Link} className="nav-link">
              <span className="mdi mdi-translate mr-1" /> {currentLanguage.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {otherLanguageOptions.map((language) => {
                return (
                  <Dropdown.Item>
                    <Nav.Link style={{ color: "black" }} onClick={() => handleLanguageChange(language.id)}>
                      {language.name}
                    </Nav.Link>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
        <Nav>
          <Dropdown as={NavItem} className="user-menu">
            <Dropdown.Toggle to="#" as={Link} className="nav-link">
              {userProfileBlock}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/profile">
                <span className="mdi mdi-account-box-outline" /> {t("Profile")}
              </Dropdown.Item>
              {currentOrganization && hasManagerAccess(userRole) && (
                <>
                  <Dropdown.Item as={Link} to="/users">
                    <span className="mdi mdi-account-multiple" /> {t("Users")}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/account">
                    <span className="mdi mdi-cog-outline" /> {t("AccountAndBilling")}
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Divider />
              <Dropdown.Item className="text-danger" onClick={logoutAction}>
                <span className="mdi mdi-logout" /> {t("LogOut")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
        <div className="mx-10">
          {currentOrganization?.imagePath && (
            <img
              src={currentOrganization?.imagePath}
              alt={currentOrganization?.name}
              className="organization_header_image"
            />
          )}
        </div>
      </Navbar>
    </header>
  );
};

SiteHeader.displayName = "SiteHeader";

export default SiteHeader;
